<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <select
            class="custom-select custom-height"
            v-model="categoryId">
            <option v-for="item in categories" :key="item.value" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="users"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td
            v-if="hidden.name"
            @click="toDetail(item.id)"
            class="pointer"
            :class="{'text-danger': !item.isActive}">
            {{ item.name }}
          </td>
        </tr>
      </template>

      <button
        v-if="$auth.hasRole(`user:${userType}:add`)"
        type="button"
        class="btn btn-link text-success"
        slot="action" @click="toDetail(0)">
        เพิ่ม
      </button>

    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_USER } from './graph'

export default {
  props: {
    userType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  mixins: [retainMixin],
  data () {
    return {
      detailView: `User${this.$form.capitalize(this.userType)}Detail`,
      rKey: `User${this.$form.capitalize(this.userType)}List`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name'],
        column: null,
        search: null,
        toolbar: null,
      },
      users: [],
      categories: [
        {text: 'ทั้งหมด', value: 0},
        {text: 'ยกเลิก', value: -1},
      ],
      categoryId: 0
    }
  },
  apollo: {
    users: {
      query () {
        return LIST_USER(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          userType: this.userType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      if (this.categoryId === -1) {
        filter.params.isActive = false
      } else {
        filter.params.isActive = true
      }
      return filter
    },
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {userId: id}
      })
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
